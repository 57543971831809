window.formSubmit = function(response) {
    // app.DOM.recaptcha.val(response);
}

$(() => {

    // trigger initial check (maybe add another at a later stage)
    if( typeof grecaptcha === 'object' && grecaptcha.execute ) {
        grecaptcha?.execute();
        console.log('Recaptcha lookup...');
    }

    // re-generate on input focus for additional checks
    app.DOM.form_input.filter('#email, #password').on('focus', () => {
        grecaptcha?.execute();
    });

    // attempt up to 3 times
    let buttonHoverCount = 0;
    app.DOM.form_input.filter('button').on('mouseenter', () => {

        if( buttonHoverCount === 2 || grecaptcha?.getResponse() ) {
            return;
        }

        buttonHoverCount++;

        grecaptcha?.execute();
    });
});